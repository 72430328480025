import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/sfilearn.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/Fray.png";
import projImg5 from "../assets/img/authenticast.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Button, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion"
import { ArrowRight, ArrowRightShort } from "react-bootstrap-icons";
import img1 from '../assets/img/Untitled design (25).png'
import img2 from '../assets/img/Untitled design.jpg'
import img3 from '../assets/img/Copy of Untitled.png'

export const Projects = () => {

  const [buttonWidth, setbuttonWidth] = useState('50%')
  const [isClicked, setIsClicked] = useState(false)
  const [selectedButton, setSelectedButton] = useState('BizzyBook')


  // const selectedButton = useRef(null)

  const projects = [
    {
      title: "BizzyBook",
      description: "The best way to manage your schedule with none of the hassle. List your services. Get discovered. Get Booked. 100% FREE. Set up you account today!",
      imgUrl: img1,
    },
    {
      title: "Fray",
      description: "Fray is an easy-to-play, social elimination, role-playing card game where players must do all they can (including work together) to eliminate the Atrocities (bosses) & all other players in order to declare victory. You can play with 2 to up to 8 Players online (12+ in-person) in this epic free for all battle!",
      imgUrl: img2,
    },
    {
      title: "BZZZRRRT",
      description: "The #1 Threads Content Creation and Management tool. On BZZZRRRT.com you get access to data driven content and AI tools. Sign up for your free account today and get access. Create engainging content. Monitor performance. Easy Peasy.",
      imgUrl: img3,
    },

  ];


  function handleClick(target, value) {
    console.log(target)
    setSelectedButton(value)
  }

  useEffect(() => {
    console.log(selectedButton)
  }, [selectedButton])


  return (
    <section className="project" style={{ backgroundColor: '#ffffff' }}>
      <div id="projects" style={{ marginBottom: 30 }}>
      </div>

      <Grid item container xs={12} style={{ backgroundColor: '#ffffff', padding: 20, flexFlow: 'row' }}>
        <Grid item container xs={3} style={{}}>
          <Grid item container xs={12} style={{ flexFlow: 'column', }}>
            {projects.map(project => {
              const title = project.title
              return (
                <motion.button
                  onClick={(e) => { handleClick(e.target, `${title}`) }}
                  whileHover={selectedButton !== `${title}` ? {
                    scale: 1.1,
                    // backgroundColor: '#ffffff90',
                    transition: { duration: .15 },
                  } : {}}
                  whileTap={{ scale: 0.9 }}
                  style={{
                    display: selectedButton === `${title}` && 'flex',
                    justifyContent: selectedButton === `${title}` && 'space-between',
                    alignItems: selectedButton === `${title}` && 'center',
                    padding: selectedButton === `${title}` && 15,
                    width: selectedButton === `${title}` ? '100%' : '50%', height: 50,
                    backgroundColor: selectedButton === `${title}` ? "#ffffff" : '#ffffff50',
                    transition: 'width 0.3s',
                    boxShadow: '2px 2px 6px #00000040',
                    borderRadius: 40,
                    marginTop: 10,
                  }} >{project.title}
                  {selectedButton === `${title}` && <ArrowRight />}
                </motion.button>
              )
            }

            )}


          </Grid>
        </Grid>
        <Grid item container xs={4} style={{ height: "80vh", zIndex: 1, marginRight: '10%' }}>
          {selectedButton &&
            <img alt="" src={projects.find(proj => proj.title === selectedButton).imgUrl} style={{ height: '100%', width: 'auto', marginLeft: '18%' }} />}
        </Grid>
        <Grid item container xs={4} style={{ height: "80vh", zIndex: 1, }}>
          {selectedButton &&
            <div>

              <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: 40 }}>{projects.find(proj => proj.title === selectedButton).title}</Typography>
              <Typography style={{ color: 'black', fontSize: 15, width: '90%' }}>{projects.find(proj => proj.title === selectedButton).description}</Typography>

            </div>
          }
        </Grid>
      </Grid>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}