import React, { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import firebase from 'firebase/compat/app';
import { auth, db } from "../firebaseConfig";


export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && email.indexOf("@") > -1) {
      onValidated({ EMAIL: email });

      // Add the email to Firestore
      db.collection("mailingList").add({ email })
        .then(() => {
          // Email added to Firestore successfully
          console.log("Email added to Firestore");
        })
        .catch((error) => {
          // Error occurred while adding email to Firestore
          console.error("Error adding email to Firestore: ", error);
        });
    }
  };

  const clearFields = () => {
    setEmail("");
  };

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Col xs={12}>
          <h3>Subscribe to our Newsletter & Never miss the latest updates</h3>
          {status === "sending" && <Alert>Sending...</Alert>}
          {status === "error" && <Alert variant="danger">{message}</Alert>}
          {status === "success" && (
            <Alert variant="success">{message}</Alert>
          )}
        </Col>
        <Col xs={12}>
          <form onSubmit={handleSubmit}>
            <div className="new-email-bx">
              <input
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
              />
              <button type="submit">Submit</button>
            </div>
          </form>
        </Col>
      </div>
    </Col>
  );
};
