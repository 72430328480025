import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"
import { Grid, Typography } from "@mui/material";
import img from '../assets/img/Untitled design (26).png'
import img2 from '../assets/img/Untitled design (28).png'
import img3 from '../assets/img/Untitled design (29).png'
import { motion } from "framer-motion"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn" id="skills">
              <h2>Services</h2>
              <p>Here is a list of the services that we offer.</p>
              <Grid item container xs={12} style={{ flexFlow: 'row' }}>


                <Grid item container xs={4} style={{ flexFlow: 'row' }}>
                  <motion.div whileHover={{
                    scale: 1.1,
                    // backgroundColor: '#ffffff90',
                    transition: { duration: .15 },
                  }}>

                    <Grid item container xs={12} style={{ flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <img alt='' src={img} style={{ height: 'auto', width: '30%', marginBottom: 24 }} />
                      <Typography> Technical Consulting</Typography>
                    </Grid>
                  </motion.div>
                </Grid>


                <Grid item container xs={4} style={{ flexFlow: 'row' }}>
                  <motion.div whileHover={{
                    scale: 1.1,
                    // backgroundColor: '#ffffff90',
                    transition: { duration: .15 },
                  }}>

                    <Grid item container xs={12} style={{ flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <img alt='' src={img2} style={{ height: 'auto', width: '30%', marginBottom: 24 }} />
                      <Typography> Technical Recruiting</Typography>
                    </Grid>
                  </motion.div>
                </Grid>


                <Grid item container xs={4} style={{ flexFlow: 'row' }}>
                  <motion.div whileHover={{
                    scale: 1.1,
                    // backgroundColor: '#ffffff90',
                    transition: { duration: .15 },
                  }}>

                    <Grid item container xs={12} style={{ flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <img alt='' src={img3} style={{ height: 'auto', width: '40%' }} />
                      <Typography> Development</Typography>
                    </Grid>
                  </motion.div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}