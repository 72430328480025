// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBS-FS_KrYnnuObAij6umN4KP6xTF49COw",
  authDomain: "cuti-business.firebaseapp.com",
  projectId: "cuti-business",
  storageBucket: "cuti-business.appspot.com",
  messagingSenderId: "716689171864",
  appId: "1:716689171864:web:ddb888b2d1ae8ab721b7ed",
  measurementId: "G-SG7HCEFXT0"
};

// Initialize Firebase
let app;

if (firebase.apps.length === 0) {
  app = firebase.initializeApp(firebaseConfig)
} else {
  app = firebase.app();
}

const db = app.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };
export default firebase;